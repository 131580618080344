import { useEffect } from 'react';

import { useRouter } from '../services/Navigation';

/**
 * FIXME: need to address flash of content when redirecting back from identity.
 * return null here until that issue is fixed.
 */
function AppSelectorRoute() {
  // TODO: Need to redirect to book-creation page as this is where user will land to setup their book
  const router = useRouter();
  useEffect(() => {
    router.push('/book-setup');
  }, [router]);

  return null;
}

export default AppSelectorRoute;
